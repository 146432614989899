import React, { useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import Cookies from 'js-cookie'
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Routes from './Routes';
import { getFooterData, getHeaderPages, getTranslationList } from './redux/actions/infoData'
import { authorize } from './redux/actions/authData';
import { getCategories, getFeaturedCategories } from './redux/actions/productData'
import { userLogoutLocal } from './redux/actions/authData'
import { getCart } from './redux/actions/cartData';
import { getUserData, getWishlist } from './redux/actions/userData';
import { isNotNull } from './shared/components/helpers/utils';
import { setLocale } from './redux/actions/language';
import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import './index.scss'
import { css } from "@emotion/core";
import {Toaster} from "react-hot-toast";

function App() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.loading.loading)
  const locale = useSelector(state => state.language.locale)
  const translations = useSelector(state => state.infoData.translations)
  const defaultLang = useSelector(state => state.userData.defaultLang)

  const doc = document.documentElement;
  const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

  const override = css`
  position: absolute;
  width: 10vw;
  height: 10vw;
  top: calc(${top}px + 40vh);
  left : 45vw;
  z-index: 10;
`;
  useEffect(() => {
    dispatch(getTranslationList())
    if (!Cookies.get('authorized')) {
      dispatch(userLogoutLocal('logout'))
    } else {
      dispatch(authorize(Cookies.get('authorized')))
    };
  }, [])

  useEffect(() => {
    //{{{<<<--- GOOGLE TAG MANAGER --->>>}}}
    const analytics = Analytics({
      app: 'awesome-app',
      plugins: [
        googleTagManager({
          containerId: 'GTM-K57Q3HJ'
        })
      ]
    });
    analytics.on();
  }, [])

  useEffect(() => {
    if (defaultLang && defaultLang !== locale) {
      localStorage.setItem('lang', defaultLang)
      dispatch(setLocale(defaultLang))
    }
  }, [defaultLang])

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getFeaturedCategories())
    dispatch(getHeaderPages())
    dispatch(getFooterData())
    if (Cookies.get('authorized')) {
      dispatch(getWishlist())
      dispatch(getUserData())
    }
    if(Cookies.get('authorized')||  localStorage.getItem('cartToken')) {
      dispatch(getCart({}))

    }
    }, [locale])

  return (

    <div className={`App + ${loading || !isNotNull(translations) ? 'loader' : ''}`}>
      <div><Toaster/></div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <>
          {isNotNull(translations) ? <Routes /> : null}
          <ClipLoader css={override} top={top} style={{ top: 300 }} color='#50E3C2' loading={loading || !isNotNull(translations)} size={150} />
        </>
      </MuiPickersUtilsProvider>
    </div>

  );
}

export default App;
