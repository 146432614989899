import {
    CHANGE_USER_DETAILS,
    GET_USER_DETAILS,
    CHANGE_USER_PASSWORD,
    GET_WISHLIST,
    ADD_TO_WISHLIST,
    SET_DEFAULT_USER_DETAILS,
    REMOVE_USER_ADDRESS,
    EDIT_USER_ADDRESS,
    CREATE_USER_ADDRESS,
    SET_POST_BRANCH,
    SET_DEFAULT_POST_BRANCH,
    DELETE_POST_BRANCH,
    EDIT_POST_BRANCH,
    ADD_NEW_CARD,
    CARD_SUCCESS,
    SET_DEFAULT_CARD,
    DELETE_CARD,
    CHANGE_USER_LANG,
    GET_ORDERS,
    TRACK_ORDER,
    RESET_PASSWORD, FILTER_ORDERS
} from './types';
import { API_URL } from '../../constants'

export const getUserData = () => {
    const config = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    }
    return {
        type: GET_USER_DETAILS,
        meta: {
            config,
            url: `${API_URL}/api/auth/user`,
        },
    }
}


export const ChangeUserData = (data) => {
    const config = {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: CHANGE_USER_DETAILS,
        meta: {
            config,
            url: `${API_URL}/api/account/update`,
        },
    }
}

export const ChangeUserPass = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: CHANGE_USER_PASSWORD,
        meta: {
            config,
            url: `${API_URL}/api/account/change-password`,
        },
    }
}

export const getWishlist = (sortBy) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_WISHLIST,
        meta: {
            config,
            url: `${API_URL}/api/wishlist?sort_by=${sortBy || "price_asc"}`,
        },
    }
}

export const AddToWishlist = (slug) => {
    const config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",

        },
    }
    return {
        type: ADD_TO_WISHLIST,
        meta: {
            config,
            url: `${API_URL}/api/wishlist/${slug}`,
        },
    }
}


export const addUserAddress = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: CREATE_USER_ADDRESS,
        meta: {
            config,
            url: `${API_URL}/api/account/create-new-address`,
        },
    }
}

export const editUserAddress = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: EDIT_USER_ADDRESS,
        meta: {
            config,
            url: `${API_URL}/api/account/edit-shipping-address`,
        },
    }
}


export const removeUserAddress = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: REMOVE_USER_ADDRESS,
        meta: {
            config,
            url: `${API_URL}/api/account/remove-shipping-address`,
        },
    }
}

export const setDefaultUserAddress = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: SET_DEFAULT_USER_DETAILS,
        meta: {
            config,
            url: `${API_URL}/api/account/set-default-shipping-address`,
        },
    }
}

export const setDefaultPostBranch = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: SET_DEFAULT_POST_BRANCH,
        meta: {
            config,
            url: `${API_URL}/api/account/set-default-post-branch`,
        },
    }
}

export const deletePostBranch = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: DELETE_POST_BRANCH,
        meta: {
            config,
            url: `${API_URL}/api/account/delete-post-branch`,
        },
    }
}

export const setPostBranch = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: SET_POST_BRANCH,
        meta: {
            config,
            url: `${API_URL}/api/account/set-post-branch`,
        },
    }
}

export const editPostBranch = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: EDIT_POST_BRANCH,
        meta: {
            config,
            url: `${API_URL}/api/account/edit-post-branch`,
        },
    }
}

export const addNewCard = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data
    }
    return {
        type: ADD_NEW_CARD,
        meta: {
            config,
            url: `${API_URL}/api/account/add-new-card`,
        },

    }
}


export const cardSuccess = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data

    }
    return {
        type: CARD_SUCCESS,
        meta: {
            config,
            url: `${API_URL}/api/account/card-success`,
        },
    }
}

export const setCardDefault = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data

    }
    return {
        type: SET_DEFAULT_CARD,
        meta: {
            config,
            url: `${API_URL}/api/account/set-default-card`,
        },
    }
}

export const deleteCard = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data

    }
    return {
        type: DELETE_CARD,
        meta: {
            config,
            url: `${API_URL}/api/account/delete-card`,
        },
    }
}


export const getOrders = () => {
    const config = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },

    }
    return {
        type: GET_ORDERS,
        meta: {
            config,
            url: `${API_URL}/api/account/orders`,
        },
    }
}
export const filterOrders = (start, end) => {
    const config = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },

    }
    return {
        type: FILTER_ORDERS,
        meta: {
            config,
            url: `${API_URL}/api/account/orders?start_date=${start}&end_date=${end}`,
        },
    }
}


export const ChangeUserLang= () => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    }
    return {
        type: CHANGE_USER_LANG,
        meta: {
            config,
            url: `${API_URL}/api/account/change-default-lang`,
        },
    }
}

export const TrackOrder = (trackNum) => {
    const config = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },

    }
    return {
        type: TRACK_ORDER,
        meta: {
            config,
            url: `${API_URL}/api/track-order?tracking_number=${trackNum}`,
        },
    }
}

export const resetPassword = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data

    }
    return {
        type: RESET_PASSWORD,
        meta: {
            config,
            url: `${API_URL}/api/auth/reset-password`,
        },
    }
}