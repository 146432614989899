
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import Login from '../../../resources/icons/Login';
import MyHeart from '../../../resources/icons/MyHeart';
import PersonalPage from '../../../resources/icons/PersonalPage';
import Basket from '../../../resources/icons/Basket'
import PopUp from '../../ui/popup/index'
import LoginShop from '../../../assets/images/LoginShop.png'
import Signin from '../../components/sigin/index'
import CancelVector from '../../../resources/icons/CancelVector'
import {useDispatch, useSelector} from 'react-redux';
import SearchIcon from '../../../resources/icons/SearchIcon';
import Remove from '../../../resources/icons/Remove';
import Search from '../../../resources/icons/Search';
import ManuLine from '../../../resources/icons/MenuLine';
import ArrowDown from "../../../resources/icons/ArrowDown"
import HaypostLogoPNG from "../../../assets/images/haypostLogo.png"
import {handleLangChange, isNotNull} from '../helpers/utils';
import './style.scss';
import { Button, Dialog } from '@material-ui/core';
import { ResultDone } from '../../../resources/icons';
import SvgEmailIcon from "../../../resources/icons/EmailIcon"
import MyOrders from "../../../resources/icons/MyOrders";
import phone from "../../../assets/images/phone_icon.svg";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {searchProduct} from "../../../redux/actions/productData";



export default function HeaderContact(props) {
  const coockiesData = useSelector(state => state.authData.userAuth?.access_token);
  const headerPages = useSelector(state => state.infoData.headerPages);
  const cartItemsCount = useSelector(state => state.cartData.cartItemsCount)
  const wishlistCount = useSelector(state => state.userData.wishlistCount)
  const category = useSelector(state => state.productData.categories);
  const translations = useSelector(state => state.infoData.translations)
  const locale = useSelector(state => state.language.locale)
  const userDetails = useSelector(state => state.userData?.details?.data)
  const dispatch = useDispatch()
  const [language, setLanguage] = useState('')
  const history = useHistory()
  const wrapperRef = useRef(null)
  const buttonWrapperReft = useRef(null)
  const [activeMenu, setActiveMenu] = useState()
  const [activeMenuList, setActiveMenuList] = useState([])
  const [image, setImage] = useState('')
  const [anchorEl, setAnchorEl] = useState(false);
  const [Visible, setVisible] = useState(true);
  const [activePopUp, setActivePopUp] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [registerSuccessPopUp, setRegisterSuccessPopUp] = useState(false);
  const [verifymessagePopUp, setVerifymessagePopUp] = useState(false)

  useEffect(() => {
    if (category.length) {
      setActiveMenuList(category[0].children)
      setImage(category[0].image)
      setActiveMenu(category[0].slug)
    }
  }, [category])

  useEffect(() => {
    if (activeMenu) {
      let result = category.find(el => el.slug === activeMenu)
      if (!isNotNull(result.children)) {
        handleCatalogPopover(false)
        history.push("/category/" + activeMenu)
      } else {
        setActiveMenuList(result.children)
        setImage(result.image)
      }
    }

  }, [activeMenu])
  useEffect(() => {

    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !buttonWrapperReft.current.contains(event.target)) {
        handleCatalogPopover(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonWrapperReft]);

  function hideMe() {
    setVisible(prev => !prev);
  }

  useEffect(() => {
    if (coockiesData) {
      setActivePopUp(false)
    }
  }, [coockiesData])

  // const changeSearchValue = (value) => {
  //   setSearchValue(value)
  //   console.log("value",value)
  //   dispatch(searchProduct(value, 1,"" ,))
  //
  // }
  const activePopUpHandler = () => {
    setActivePopUp(true)
  }
  const pasivePopUpHandler = () => {
    setActivePopUp(false)
  }
  const closeRegisterSuccessPopUp = () => {
    setRegisterSuccessPopUp(false)
  }
  const backHomePageHandler = () => {
    history.push('/')
  }
  const closeVerifyMessagePopUp = () => {
    setVerifymessagePopUp(false)
  }

  const toBasketPageHandler = () => {
    history.push('/basket')
  }
  const toPersonalPageHandler = () => {
    history.push('/personalPgaes')
  }
  const toMyOrders = () => {
    history.push('/personalPgaes/component-order')
  }
  const toMyFavoritesHandler = () => {
    history.push('/PersonalPgaes/my-product')
  }

  const handleCatalogPopover = (bool) => {
    setAnchorEl(bool)
  }
  useEffect(() => {
    setLanguage(locale || 'hy')
  }, [locale])

  const handleChangeLanguage = (value) => {
    setLanguage(value);
    handleLangChange(value, dispatch)
  };
  const languages=[
    {key:"hy",label:'Հայերեն'},
    {key:"en",label:'English'},
    {key:"ru",label:'Русский'},
  ];



  return (
    <React.Fragment>
      <div className="header-contact" tabIndex="1">
        <div className="header-logo">
          <img src={HaypostLogoPNG} alt='logo' onClick={backHomePageHandler} />
        </div>
        <span className="banner-info-contact-text">
        <img src={phone}/> {translations['HEADER_PHONE'][language]}
      </span>

        <div className={"header-center"}>
          <div className={"main_menu_button flex flex_i_center"}
               ref={buttonWrapperReft}
               onClick={(e) => {
                 handleCatalogPopover(!anchorEl)
               }}>
            Բոլորը
          </div>
          <form className="search-container">
            <input className="header-input" type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={translations['SEARCHING'][locale]} name="search" />
            <button className="search-icon" onClick={() => history.push('/product-search/' + searchValue)} type="submit">
               {translations['SEARCH'][locale]}
            </button>
          </form>
          {anchorEl ? <div
              className='catalogWrapper inherit_a light_menu'
              ref={wrapperRef}
              tabIndex="0"
          >
            <div className="categories">
              {
                category.map((item, index) => (
                    <div className="categoryItem" key={index} onClick={() => setActiveMenu(item.slug)}>
                      <div className="categoryItemImg">
                        <img src={item.icon} />
                      </div>
                      <p style={{ marginLeft: 5 }}>{item.name}</p>
                    </div>
                ))
              }
            </div>
            <div className="subcategoriesWrapper">
              <div className="subcategories">
                {activeMenuList.map((item, index) => (
                    <div style={{}} className="subcategory" key={index}>
                      <Link className="categoryLinkHeader" to={"/category/" + activeMenu + "/" + item.slug} onClick={() => handleCatalogPopover(false)}>{item.name}</Link>
                      {
                        item.children.map((child, indx) => (
                            <Link key={indx} className="categoryLinkItem" to={"/category/" + activeMenu + "/" + item.slug + "/" + child.slug} onClick={() => handleCatalogPopover(false)}>{child.name}</Link>
                        ))
                      }
                    </div>
                ))}
              </div>
              {image && <div className="imgWrapper">
                <div className='categories-img'>
                  <img src={image} alt="" />
                </div>
                <p>{translations['NEW_MODELS'][locale]}</p>
              </div>}
            </div>
          </div> : null}
        </div>

        <div className="toolbarWrapper">
          <div className="lang_swithcer" >
              <span className={'lang_toggle'}>
                {languages.map((item)=>{
                  if(item.key ==locale){
                    return  <span className={'selected_lang '+item.key} onClick={()=>{return handleChangeLanguage(item.key)}}>{item.label}</span>
                  }
                })}
              </span>

              <ul className={'lang_ul'}>
                {languages.map((item)=>{
                  if(item.key !=locale){
                    return  <li className={'item '+item.key} onClick={()=>{return handleChangeLanguage(item.key)}}>{item.label}</li>
                  }
                })}
              </ul>
          </div>
          <div className="contact-icons">
            {!coockiesData ? <div onClick={activePopUpHandler} className="contact-login">
              <Login />
              <p>{translations['LOGIN'][locale]}</p>
            </div> :
              <React.Fragment>
                {/*<div onClick={toMyFavoritesHandler} className="contact-myfavorite">*/}
                {/*  <div className="contact-basket-icon" style={{ display: "flex", justifyContent: "center", paddingLeft: "25px" }}>*/}
                {/*    <MyHeart />*/}
                {/*    <div className="contact-basket-quantity">{wishlistCount}</div>*/}
                {/*  </div>*/}
                {/*  <p>{translations['FAVORITES'][locale]}</p>*/}
                {/*</div>*/}
                <div onClick={toPersonalPageHandler} className="contact-login">
                  <PersonalPage />
                  <p>{translations['HELLO'][locale]} {userDetails?.full_name}</p>
                </div>
                <div onClick={toMyOrders} className="contact-login">
                  <MyOrders />
                  <p>{translations['TRACK_ORDER'][locale]}</p>
                </div>
              </React.Fragment>
            }
            <div onClick={toBasketPageHandler} className="contact-basket" id="basket-icon">
              <div className="contact-basket-icon" >
                <Basket />
                <div className="contact-basket-quantity">{cartItemsCount}</div>
              </div>
              <p>{translations['BASKET'][locale]}</p>
            </div>
          </div>

        </div>
      </div>
      {/*<div className={"header-top-menu"}>*/}
      {/*  {*/}
      {/*    category.map((item, index) => (*/}
      {/*        <Link to={"/category/"+item.slug+"/"} className="header-top-menu-item" key={index}>*/}
      {/*          {item.name}*/}
      {/*        </Link>*/}
      {/*    ))*/}
      {/*  }*/}
      {/*</div>*/}
      <PopUp onClose={pasivePopUpHandler} active={activePopUp}>
        <CancelVector onClick={pasivePopUpHandler} className='sign-popup-cancale-icon' />
        <img src={LoginShop} />
        <Signin
          setRegisterSuccessPopUp={setRegisterSuccessPopUp}
          setVerifymessagePopUp={setVerifymessagePopUp}
          active={activePopUp}
          onClosePopup={() => setActivePopUp(false)}
        />
      </PopUp>
      <Dialog
        className="register-sucsses-popUp"
        onClose={closeRegisterSuccessPopUp}
        open={registerSuccessPopUp}
      >
        <div className='register-sucsses-content'>
          <ResultDone />
          <p>{translations['CHECK_YOUR_EMAIL'][locale]}</p>
          <Button
            variant="contained"
            onClick={closeRegisterSuccessPopUp}
          >{translations['OK'][locale]}</Button>
        </div>
      </Dialog>
      <Dialog
        className="register-sucsses-popUp"
        onClose={closeVerifyMessagePopUp}
        open={!!verifymessagePopUp}
      >
        <div className='register-sucsses-content'>
          <SvgEmailIcon />
          <p>{verifymessagePopUp}</p>
          <Button
            variant="contained"
            onClick={closeVerifyMessagePopUp}
          >{translations['OK'][locale]}</Button>
        </div>
      </Dialog>
    </React.Fragment>
  )
}
